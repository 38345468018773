<template>
  <div class="content_box">
    <!-- 顶部图片 -->
    <div class="head_img_box">
      <el-image :src="headImg">
        <div slot="placeholder" class="image-slot">
          <img :src="loadImg" class="loadImg" alt="" />
        </div>
      </el-image>
      <!-- 使用方法 -->
      <div class="usage_box">
        <div class="title">暢遊廣島必備的景點套票，精選廣島多個人氣設施與美食店家，一週內可以任選三個喜歡的設施，出示電子票券QR code就可以輕鬆入場體驗。</div>
        <div class="syff">
          <div class="syff_left">
            <p></p>
            <p></p>
          </div>
          <div class="txt">使用方法</div>
          <div class="syff_right">
            <p></p>
            <p></p>
          </div>
        </div>
        <div class="neiron">
          ●同設施只可以使用一次，相同設施不可再入場<br>
          ●票劵使用效期為購買日起<span>90天內</span><br>
           EX:<span>7/1</span>購買票券⇒票期:<span>7/1~9/28</span><br>
          ●票券有效期限: 使用第一個設施後，<span>7日內</span>須使用完畢<br>
          EX:<span>7/1</span>開始使用，<span>有效期限至7/7</span><br>
          ●有效期間內可任<span>選3項設施</span><br>
          ●注意: 單次購買複數票劵時，當其中一張票劵開始用，所有票劵也將同步開通 (<span>使用效期7天</span>)<br>
          ●票券開通後，7天的使用效期無法超過票券<span>90天</span>的有效期間。<br>
           EX:票券期限<span>7/1~9/28</span>，若<span>9/27</span>開始使用，其使用效期為<span>9/27~9/28</span><br>
          ●無使用完畢不能兌現<br>
          ●請事先於以下連結確認各設施注意事項・營業時間・公休日情報
        </div>
      </div>
      <!-- 景点介绍 -->
      <div class="main_box" v-for="(item,i) in dataList" :key="i">
        
        <!-- 图片 -->
        <div class="img_box">
          <el-image :src="item.img" lazy>
            <div slot="placeholder" class="image-slot">
              <img :src="loadImg" class="loadImg" alt="" />
            </div>
          </el-image>
        </div>
        
        <!-- 文字 -->
        <div class="text_box">
          <div class="title" v-for="(title,i) in item.title" :key="i" v-html="title"></div>
          <hr>
          <div class="message_box">
            <div class="message_item" v-for="msg in item.message" :key="msg.msgId">
              <div style="margin-bottom:5px">{{msg.title}}</div>
              <div v-if="msg.title!=='注意事項'">
                <div v-for="contentItem in msg.content" :key="contentItem.cid">
                  <span v-for="(cItem,i) in contentItem.text" :key="i">
                    <span v-show="msg.title==='兌換地點' && !contentItem.noColor">{{cItem}}<br></span>
                    <a :style="[{'borderBottom': !contentItem.noColor ? '1px solid' : ''},{color: !contentItem.noColor ? '#219DCC' : ''},{'wordWrap': 'break-word'}]" target="_blank" :href="cItem" v-show="msg.title==='官方網站' && !contentItem.noColor">{{cItem}}<br></a>
                    <p v-show="contentItem.noColor" v-html="cItem"></p>
                  </span>
                </div>
              </div>
              <!-- 注意事項特殊处理 -->
              <!-- :style判断是否要数字前缀 -->
              <div :style="{marginLeft:msg.isDecimal ? '16.5px' : '0px'}" v-else>
                <ul :style="{listStyleType:msg.isDecimal ? 'decimal' : 'none'}">
                  <li v-for="contentItem in msg.content" :key="contentItem.cid">
                    <span v-html="cItem" v-for="(cItem,i) in contentItem.text" :key="i"></span>
                  </li>
                </ul>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 景点介绍 end -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      headImg: require('@/assets/images/hfhiroshima/tc/havefun_title_tc.jpg'),
      loadImg: require('@/assets/images/hfkansai/loading.png'),
      dataList: [
        {
          id: 1,
          img: require('@/assets/images/hfhiroshima/tc/ferry_TC.png'),
          title: ['廣島港-吳港 遊輪單程票'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '1.廣島港宇品港灣大樓1F 窗口','地址：廣島市南區宇品海岸１丁目13-26','2.吳港（中央碼頭）航站樓1F窗口','地址：吳市寶町4-44'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://setonaikaikisen.co.jp/kouro/cruise/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['可用路線：','<br>・廣島港→呉港<br>・呉港→廣島港','<br>※單程票只適用於其中一段路線。'] },
                { cid: 2, text: ['如果您未在窗口進行二維碼驗證，將無法登船，請注意。'] },
                { cid: 3, text: ['不提供廣島到呉的高速船（超級噴射艇）服務。'] },
                { cid: 4, text: ['請參閱官方網站上的時刻表。'] },
                ]
            }
          ]
        },
        {
          id: 2,
          img: require('@/assets/images/hfhiroshima/tc/ferry2_TC.jpg'),
          title: ['廣島港-宮島港 高速遊輪 1000日圓優惠券'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '1.廣島港宇品港灣大樓1F ①or②號窗口','地址：廣島市南區宇品海岸１丁目13-26','2.廣島格蘭王子大飯店旅遊咨詢台','地址：〒734-0012 廣島市南區元宇品町23-1','3.宮島港3號碼頭售票處','地址：廿日市市宮島町胡町地先'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://setonaikaikisen.co.jp/kouro/highspeedship/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['可使用路線：','<br>・廣島港→宮島港<br>・王子飯店前→宮島港<br>・宮島港→廣島港<br>・宮島港→王子飯店前','<br>※您可以在以上任一路段享受1000日元的優惠券。'] },
                { cid: 2, text: ['如果您未在窗口進行二維碼驗證，將無法登船，請注意。'] },
                { cid: 3, text: ['請參閱官方網站上的時刻表。'] },
                { cid: 4, text: ['費用若有差額需要支付的話，請在現場繳交。'] },
                { cid: 5, text: ['可能因客滿而無法乘坐，敬請見諒。'] },
                ]
            }
          ]
        },
        {
          id: 3,
          img: require('@/assets/images/hfhiroshima/tc/Orizuru_TC.png'),
          title: ['紙鶴塔門票 1000日圓優惠券'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '〒730-0051 廣島縣廣島市中區大手町一丁目2番1號(1F 咨詢櫃台)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://www.orizurutower.jp/zh-CN/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['請盡情使用1000日元優惠券。'] },
                { cid: 2, text: ['有可能因活動等而臨時休館，詳細情況請在出發前至官網確認。'] },
                { cid: 3, text: ['【關於再入場】','<br>・僅限入場當天，可多次重覆入場。<br>・需要再次入場的話，請於出口告知工作人員，並在手上蓋章。再次入場需要確認入場券和印章。'] },
                { cid: 4, text: ['【使用展望台】','<br>・為了防止在館內發生事故，可能會拒絕攜帶行李箱或大件行李等本館認為不合適的物品進入。有投幣式儲物櫃 (1樓入口旁邊/收費) ，請使用。<br>・嚴禁攜帶在館外購買的飲食品入場。<br>・嚴禁使用三腳架拍照，以免給其他客人帶來麻煩。'] },
                { cid: 5, text: ['有可能因天氣原因無法完全觀賞到風景、或因強風等而關閉的狀況，敬請注意。'] },
                ]
            }
          ]
        },
        {
          id: 4,
          img: require('@/assets/images/hfhiroshima/tc/Peacecle_TC.png'),
          title: ['廣島市共用自行車「Peacecle」1日券 【廣島市內】'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '〒732-0828　廣島市南區京橋町2-29　SS大樓1F(Peacecle運營事務所)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://docomo-cycle.jp/hiroshima/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['其他一日券櫃台不受理二維碼認證。'] },
                { cid: 2, text: ['當日23:59前有效。'] },
                { cid: 3, text: ['自行車僅限身高145cm以上者使用。'] },
                { cid: 4, text: ['請歸還至市內140個自行車專用停放處之一。'] },
                { cid: 5, text: ['部分自行車的使用/歸還時間有限制。詳情請查看官網。'] },
                { cid: 6, text: ['騎自行車時必須戴安全帽。如果您需要安全帽這邊提供付費出租。'] },
                ]
            }
          ]
        },
        {
          id: 5,
          img: require('@/assets/images/hfhiroshima/tc/ekie_TC.png'),
          title: ['JR廣島站前購物中心「ekie」1,500日圓禮券'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '〒732-0822　廣島市南區松原町1-2 (2樓服務台)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://www.ekie.jp/lang/tw.html'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['結帳時請在各店鋪出示禮券。(出示二維碼無法使用。請務必在使用前兌換成禮券。)'] },
                { cid: 2, text: ['本券不可兌換現金或找零。'] },
                { cid: 3, text: ['因臨時休業、滿員等原因不能使用的情況也不會退款。'] },
                { cid: 4, text: ['禮券對象外店鋪：日本旅行、7-11 Heart in、伊達眼科診所、紅葉藥局、廣島站大樓 歌野原牙科診所、廣島站內科·兒童診所、河合塾manabisu'] },
                { cid: 5, text: ['禮券(實體劵)有效期為7天。'] },
                ]
            }
          ]
        },
        {
          id: 6,
          img: require('@/assets/images/hfhiroshima/tc/Sozo_TC.png'),
          title: ['「廣島御好燒站前廣場」1,500日圓禮券'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '〒732-0822 廣島縣廣島市南區松原町10-1 廣島Full Focus大樓6F「御好燒Sozo」'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://ekimae-hiroba.jp/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['結帳時，請在各店出示商品券（請務必先兌換成商品券，不可使用二維碼支付）。'] },
                { cid: 2, text: ['不可兌換成現金。'] },
                { cid: 3, text: ['對於失竊或丟失，本公司概不負責。'] },
                { cid: 4, text: ['若臨時休館・客滿等因素遊客無法使用的話仍舊無法退款。'] },
                ]
            }
          ]
        },
        {
          id: 7,
          img: require('@/assets/images/hfhiroshima/tc/Bic_Camera_TC.png'),
          title: ['JR廣島站前家電量販店「Bic Camera 廣島站前店」1000日圓禮券'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '〒732-0822　廣島縣廣島市南區松原町5-1　1樓綜合服務櫃台'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://www.biccamera.com.t.lj.hp.transer.com/bc/c/super/okaimono/oshiharai/coupon/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['結帳時，請在各店出示商品券（請務必先兌換成商品券，不可使用二維碼支付）。'] },
                { cid: 2, text: ['不可兌換成現金。'] },
                { cid: 3, text: ['對於盜竊、遺失或減失等情況，我們不負任何責任。'] },
                { cid: 4, text: ['不接受其他類型的商品券、明信片、郵票、香煙或其他我們指定以外的商品兌換。'] },
                { cid: 5, text: ['若因臨時休業、客滿等原因無法使用，依舊不提供退款。'] },
                ]
            }
          ]
        },
        {
          id: 8,
          img: require('@/assets/images/hfhiroshima/tc/LobbyLounge_TC.jpg'),
          title: ['廣島格蘭比亞大酒店 大廳咖啡店 「Lobby Lounge」蛋糕套餐'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '〒732-0822 廣島縣廣島市南區松原町1-5 大廳咖啡店 「Lobby Lounge」(大廳夾層)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://www.jrwesthotels.com/tw/hotels/hiroshima/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['包含:多種甜點師特製的蛋糕以及咖啡或紅茶。'] },
                { cid: 2, text: ['營業時間請事先至官網確認。','<br>※可能因臨時休業・客滿而無法使用。'] },
                { cid: 3, text: ['產品圖片僅供參考。'] },
                { cid: 4, text: ['額外的費用均由客人承擔。'] },
                { cid: 5, text: ['不可以外帶。'] },
                ]
            }
          ]
        },
        {
          id: 9,
          img: require('@/assets/images/hfhiroshima/tc/dahe_TC.jpg'),
          title: ['大和博物館入場券+館內商店1000日圓優惠券'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '〒737-0029　廣島縣吳市寶町5番20號 (入口)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://yamato-museum.com/leaflet/guidance-leaflet-traditional-chinese/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['請於入館時領取館內1000日圓優惠券。'] },
                { cid: 2, text: ['1000日圓優惠券可在館內商店使用，恕不找零。'] },
                { cid: 3, text: ['營業時間請事先至官網確認。'] },
                ]
            }
          ]
        },
      ]
    }
  },
  created () {
    // this.$router.push('/hfPage?id=HFHiroshima&l=tc')
    window.open('https://www.travelcontentsapp.com/have-fun/have-fun-in-hiroshima-1-week-free-pass/tc', '_self')
  }
}
</script>
<style lang="scss" scoped>
.content_box {
  font-family: "Genseki";
  position: relative;
  width: 100%;
  height: 100%;
  // 使用方法
  .usage_box {
    background-color: #FFFBA0;
    padding: 18px 20px;
    margin: 20px 15px;
    color: #1A1311;
    font-weight: 700;
    margin-top: 0;
    .title{
      font-size: 13px;
      line-height: 19px;
    }
    .syff {
      display: flex;
      justify-content: space-between;
      margin: 18px 0;
      .txt {
        font-size: 21px;
        margin: 0 8.5px;
      }

      .syff_left,
      .syff_right {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        p{
          display: inline-block;
          height: 1px;
          width: 100%;
          background-color: #707070;
          margin-bottom: 2px;
        }
      }
    }
    .neiron {
      line-height: 21px;
      font-size: 12px;
      span{
        color: #FA6460;
      }
    }
  }
  .main_box {
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 26px;
    .img_box {
      width: 100%;
      box-shadow: 6px 0px 6px rgba(0, 0, 0, 0.16);
    }
    
    .text_box {
      position: relative;
      margin-top: 22px;
      padding: 0 10px;
      // width: 90%;
      // padding-left: 24px;
      // padding-right: 17px;
      .title {
        font-size: 17px;
        font-weight: bolder;
        color: #876b57;
        margin: 5px 0;
        /deep/span {
          color:#ff0000;
        }
      }
      .message_box {
        font-size: 13px;
        line-height: 17px;
        font-weight: bold;
        margin-top: 5px;
        .message_item:not(:first-child) {
          margin-top: 22px;
        }
      }
    }
  }
}
.loadImg {
  width: 100%;
  height: auto;
}
hr {
  border: none;
  height: 1px;
  background-color: #707070;
}
a:link {
  color: #000;
}
a:visited {
  color: #000;
}
a:hover {
  color: #000;
  border-bottom: 1px solid;
}
a:active {
  color: #000;
}
</style>